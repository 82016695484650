// src/components/Projects.js
import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FiExternalLink } from 'react-icons/fi'; // Import the external link icon
import Image from 'react-bootstrap/Image';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

const Projects = () => {
  return (
    <Container className='pt-5 georgia-font'>
      <h1 className='font-bold text-center mb-4'>PROJECTS</h1>
      <Row className='justify-content-center'>
        <Col md={10}>
          <Card className='mb-4 shadow-sm'>
            <Card.Body>
              <Card.Title className='text-primary'>Creto Games(Event Registration)</Card.Title>
              <Row className='align-items-center'>
                <Col md={4}>
                  <Image src='/images/cg.png' fluid alt='' />
                </Col>
                <Col md={8}>
                  <Card.Text>
                   
Creto Games is a gaming platform where players can buy tickets or book their slot to participate in room matches. It is primarily created for Free Fire and was inspired by Fi8.com during the Covid-19 pandemic period. Here, all validation and verification options are available.
                  </Card.Text>
                  <Card.Text className='text-muted'>
                    Php, Javascript, HTML, CSS, Bootstrap, Mysql
                  </Card.Text>
                  <Button variant='link' href='https://cretogames.in/' target='_blank'>
                    <FiExternalLink size='30px' /> 
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col md={10}>
          <Card className='mb-4 shadow-sm'>
            <Card.Body>
              <Card.Title className='text-primary'>Dallet(Wallet)</Card.Title>
              <Row className='align-items-center'>
                <Col md={4}>
                  <Image src='/images/dallet.png' fluid alt='' />
                </Col>
                <Col md={8}>
                  <Card.Text>
                  Dallet is a wallet that allows users to send cryptocurrencies from any account without needing the recipient's wallet address. It also enables users to connect their MetaMask wallet to send and receive cryptocurrencies. Dallet supports both Sepolia (testnet) and Ethereum mainnet, allowing users to operate on both testnet and mainnet environments.
                    <a className='text-primary' href='https://dallet.cretogames.in/' target='_blank' rel='noopener noreferrer'> Visit Dallet</a>
                  </Card.Text>
                  <Card.Text className='text-muted'>
                    Web3.JS, HTML, CSS, React
                  </Card.Text>
                  <Button variant='link' href='https://dallet.cretogames.in/' target='_blank'>
                    <FiExternalLink size='30px' /> {/* Use the external link icon */}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Projects;
