import React from "react";
import Skills from "./Skills";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import './style.css';

const Mydet = () => {
  return (
    <div className="h-screen lg:pl-20 lg:pr-32 georgia-font p-5" >
      <Container fluid className="md:mx-20 md:pt-32 pt-28">
        <Row >
          <Col md={12} className="md:ml-20 px-10 mt-10 "  style={{border:'solid  1px'}}>
            <motion.div
              className="md:w-96"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <h1 className="text-3xl font-bold sm:text-4xl mb-8" style={{fontWeight:'bold'}}>
                Hello, I'm <span style={{ color: "#3B82F6" }}>Dinesh. S</span>
              </h1>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <h2 className="font-bold text-3xl text-left mb-5">
                I'm a <span className="text-blue-500">FullStack Developer</span> with a
                passion for <span style={{ color: "#3B82F6" }}>Blockchain</span>.
              </h2>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
            >
              <p className=" mb-5" style={{fontSize:'18px'}}>
                I am pursuing graduation from , KIOT, Salem (636105). Most of
                my experience is in full stack web development . Check out some of my work{" "}
                <a
                  href="https://github.com/dinesh-aamous"
                  className="underline text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>.
              </p>
              <p className="text-left font-normal mb-5" style={{fontSize:'18px'}}>
                In my free time, I love to learn new technologies and keep up-to-date with full stack development. Outside of programming, I enjoy reading novels and watching anime. I've learned a lot from anime. One of my favorite characters is Shinchan.
              </p>
            </motion.div>
          </Col>
          <Col>
            <Skills />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Mydet;
