import React from "react";
import { motion } from "framer-motion";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { FaReact, FaHtml5, FaCss3Alt, FaGithub, FaJs, FaPhp } from "react-icons/fa";
import { SiMysql, SiWeb3Dotjs, SiSolidity } from "react-icons/si";


const Skills = () => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          scale: 0.8,
          opacity: 0,
        },
        visible: {
          scale: 1,
          opacity: 1,
          transition: {
            delay: 2.0,
          },
        },
      }}
    >
      <Container fluid className=" pb-32" >
        <div className="mx-auto">
          <p className="font-bold  pb-4 text-center" style={{textAlign:'center', fontSize:'30px', fontWeight:'bolder' }}>SKILLS</p>

          <Row className="flex-wrap gap-4 mb-4">
            <Col md={4}>
              <ListGroup className="bg-blue-50 p-4 rounded-2xl">
                <ListGroup.Item variant="primary" className="text-blue-500 text-2xl text-center">
                  Frontend
                </ListGroup.Item>
                <ListGroup.Item className="text-xl font-bold d-flex justify-content-center align-items-center flex-column">
                  <FaReact size="40px" />
                  React
                </ListGroup.Item>
                <ListGroup.Item className="text-xl font-bold d-flex justify-content-center align-items-center flex-column">
                  <FaHtml5 size="40px" />
                  HTML
                </ListGroup.Item>
                <ListGroup.Item className="text-xl font-bold d-flex justify-content-center align-items-center flex-column">
                  <FaCss3Alt size="40px" />
                  CSS
                </ListGroup.Item>
                <ListGroup.Item className="text-xl font-bold d-flex justify-content-center align-items-center flex-column">
                  <FaJs size="40px" />
                  JavaScript
                </ListGroup.Item>
                <ListGroup.Item className="text-xl font-bold d-flex justify-content-center align-items-center flex-column">
                  <SiWeb3Dotjs size="40px" />
                  Web3.js
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col md={3}>
              <ListGroup className="bg-blue-50 p-4 rounded-2xl">
                <ListGroup.Item variant="primary" className="text-blue-500 text-2xl text-center">
                  Backend
                </ListGroup.Item>
                <ListGroup.Item className="text-xl font-bold d-flex justify-content-center align-items-center flex-column">
                  <SiMysql size="40px" /> 
                  MySQL
                </ListGroup.Item>
                <ListGroup.Item className="text-xl font-bold d-flex justify-content-center align-items-center flex-column">
                  <SiSolidity size="40px" />
                  Solidity
                </ListGroup.Item>
                <ListGroup.Item className="text-xl font-bold d-flex justify-content-center align-items-center flex-column">
                  <FaPhp size="40px" />
                  PHP
                </ListGroup.Item>
                <ListGroup.Item className="text-xl font-bold d-flex justify-content-center align-items-center flex-column">
                  <img src='./images/truffle.svg' alt="Hostinger" style={{ width: '40px', height: '40px' }} />
                  Truffle
                </ListGroup.Item>
                <ListGroup.Item className="text-xl font-bold d-flex justify-content-center align-items-center flex-column">
                  <img src='./images/ganache.svg' alt="Hostinger" style={{ width: '40px', height: '40px' }} />
                  Ganache
                </ListGroup.Item>
              </ListGroup>
            </Col>

            <Col md={4}>
              <ListGroup className="bg-blue-50 p-4 rounded-2xl">
                <ListGroup.Item variant="primary" className="text-blue-500 text-2xl text-center">
                  Other
                </ListGroup.Item>
                <ListGroup.Item className="text-xl font-bold d-flex justify-content-center align-items-center flex-column">
                  <FaGithub size="40px" />
                  Git/GitHub
                </ListGroup.Item>
                <ListGroup.Item className="text-xl font-bold d-flex justify-content-center align-items-center flex-column">
                  <img src='./images/hostinger.svg' alt="Hostinger" style={{ width: '40px', height: '40px' }} />
                  Hostinger
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </div>
      </Container>
    </motion.div>
  );
};

export default Skills;
