// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MNavbar from './components/MNavbar';
import Main from './components/Main';
import Projects from './components/Projects';
import Mydet from './components/Mydet'; 
import './App.css';

const App = () => {
  return (
    <div className="gradient-background">
    <img src="/icons/html.svg" alt="HTML" className="icon" />
      <img src="/icons/css.svg" alt="CSS" className="icon" />
      {/* <img src="/icons/bootstrap.svg" alt="Bootstrap" className="icon" /> */}
      <img src="/icons/react.svg" alt="React" className="icon" />
      <img src="/icons/solidity.svg" alt="Solidity" className="icon" />
      <img src="/icons/web3js.svg" alt="Web3.js" className="icon" />
    <Router>
      <MNavbar />
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route path="/projects" element={<Projects />} />
        <Route exact path="/mydet" element={<Mydet />} />

      </Routes>
    </Router>
    </div>
  );
};

export default App;
