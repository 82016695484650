// src/components/Main.js
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';


const Main = () => {
  return (
    <Container className="text-center georgia-font" style={{ marginTop: '20vh', }}>
      <p style={{ fontSize:'50px'}} >DINESH.S</p>
      <p>
        <span style={{ color: "#3B82F6", fontSize:'40px'  }}>Blockchain Developer</span>
      </p>
      <p style={{ fontSize:'30px'}} >Web Developer</p>
      <Button href='/assets/Dinesh_Resume_2024.pdf'
        className="mx-3 btn-lg"
        variant="primary"
     
        style={{ backgroundColor: "#3E517A", border: "none" }}
        onMouseEnter={(e) => e.target.style.backgroundColor = "#3B82F6"}
        onMouseLeave={(e) => e.target.style.backgroundColor = "#5a6d94"}
      >
        Resume
      </Button>
      <Button 
      href='mailto:dineshaamous@gmail.com'
        className="mx-3 btn-lg"
        variant="secondary"
        style={{ backgroundColor: "#3E517A", border: "none" }}
        onMouseEnter={(e) => e.target.style.backgroundColor = "#3B82F6"}
        onMouseLeave={(e) => e.target.style.backgroundColor = "#5a6d94"}
      >
        Contact
      </Button>
    </Container>
  );
};

export default Main;
