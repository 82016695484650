import React, { useState } from 'react';
import { Navbar, Nav, Container, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MNavbar.css';

const MNavbar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Navbar bg="light" expand="lg" className='grad-background georgia-font'>
      <Container fluid>
        <Navbar.Brand className='ncolor' as={Link} to="/">Dinesh</Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link as={Link} to="/projects" onClick={handleClose}>Projects</Nav.Link>
              <Nav.Link as={Link} to="/mydet" onClick={handleClose}>About</Nav.Link>
              <Nav.Link href="/assets/Dinesh_Resume_2024.pdf" target='_blank' onClick={handleClose}>Resume</Nav.Link>
              <Nav.Link href="https://github.com/dinesh-aamous" target="_blank" onClick={handleClose}>
                <FaGithub style={{ marginRight: '8px' }} />
                GitHub
              </Nav.Link>
              <Nav.Link href="https://www.linkedin.com/in/dinesh-s-250662319/" target="_blank" onClick={handleClose}>
                <FaLinkedin style={{ marginRight: '8px' }} />
                LinkedIn
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default MNavbar;
